import { Outlet, Link } from "react-router-dom";
import React from "react";
import Home from "../pages/Home";
import Header from "../components/Header";
import FooterMobile from "../components/FooterMobile";
//Mobile imports
import "antd/dist/antd.css";
//Images
if (window.innerWidth <= 440 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

const Layout = () => {
  return (
    <>
      <Outlet />
      <Home />
    </>
  );
};
export default Layout;
