import React from "react";
import { Row, Col, Button, Card } from "antd";
import AboutImageHeader from "../static/Home/landfill-image.png";
import AboutImageHeader1 from "../static/Home/landfill-image-1.png";
import AboutImageHeader2 from "../static/Home/landfill-image-2.png";
import AboutImageHeader3 from "../static/Home/landfill-image-3.png";
import AboutImageHeader4 from "../static/Home/landfill-image-4.png";
import AboutImageHeader5 from "../static/Home/landfill-image-5.png";
import AboutImageHeader6 from "../static/Home/landfill-image-6.png";
import AboutImageHeader7 from "../static/Home/landfill-image-7.png";
import AboutImageHeader8 from "../static/Home/landfill-image-8.png";
import AboutImageHeader9 from "../static/Home/landfill-image-9.png";
import ProductImage1 from "../static/Home/product-image-1.png";
import ProductImage2 from "../static/Home/product-image-2.png";
import ProductImage3 from "../static/Home/product-image-3.png";
import ProductImage4 from "../static/Home/product-image-4.png";
import ProductImage5 from "../static/Home/product-image-5.png";
import Sketch3 from "../static/Home/sketch-img-3.png";
import Sketch4 from "../static/Home/sketch-img-4.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Header from "../components/Header-min-mobile";
import Footer from "../components/FooterMobile";

import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const { Meta } = Card;

const AboutHeader = () => (
  <div id='about'>
    <Fade bottom>
      <p
        className='Roboto'
        style={{
          color: "white",

          fontWeight: 800,
          fontSize: "40px",
          marginBottom: "-0em",
          fontFamily: "Roboto",
        }}
      >
        OUR PROJECTS
      </p>
    </Fade>
  </div>
);
const Header1 = () => (
  <div id='about'>
    <img
      src={ProductImage5}
      style={{
        width: "95vw",
      }}
    ></img>
  </div>
);
const AboutHeader0 = () => (
  <div id='about'>
    <img
      src={AboutImageHeader}
      style={{
        width: "100vw",
        height: "22em",
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        objectFit: "cover",
        objectPosition: "center",
        objectPosition: "bottom",
      }}
    ></img>
  </div>
);
const AboutHeader1 = () => (
  <div id='about'>
    <img
      src={AboutImageHeader1}
      style={{
        width: "100vw",
        height: "22em",
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        objectFit: "cover",
        objectPosition: "center",
        objectPosition: "bottom",
      }}
    ></img>
  </div>
);
const AboutHeader2 = () => (
  <div id='about'>
    <img
      src={AboutImageHeader2}
      style={{
        width: "100vw",
        height: "22em",
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        objectFit: "cover",
        objectPosition: "center",
        objectPosition: "bottom",
      }}
    ></img>
  </div>
);
const AboutHeader3 = () => (
  <div id='about'>
    <img
      src={AboutImageHeader3}
      style={{
        width: "100vw",
        height: "22em",
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        objectFit: "cover",
        objectPosition: "center",
        objectPosition: "bottom",
      }}
    ></img>
  </div>
);
const AboutHeader4 = () => (
  <div id='about'>
    <img
      src={AboutImageHeader4}
      style={{
        width: "100vw",
        height: "22em",
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        objectFit: "cover",
        objectPosition: "center",
        objectPosition: "bottom",
      }}
    ></img>
  </div>
);
const AboutHeader5 = () => (
  <div id='about'>
    <img
      src={AboutImageHeader5}
      style={{
        width: "100vw",
        height: "22em",
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        objectFit: "cover",
        objectPosition: "center",
        objectPosition: "bottom",
      }}
    ></img>
  </div>
);
const AboutHeader6 = () => (
  <div id='about'>
    <img
      src={AboutImageHeader6}
      style={{
        width: "100vw",
        height: "22em",
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        objectFit: "cover",
        objectPosition: "center",
        objectPosition: "bottom",
      }}
    ></img>
  </div>
);
const AboutHeader7 = () => (
  <div id='about'>
    <img
      src={AboutImageHeader7}
      style={{
        width: "100vw",
        height: "22em",
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        objectFit: "cover",
        objectPosition: "center",
        objectPosition: "bottom",
      }}
    ></img>
  </div>
);
const AboutHeader8 = () => (
  <div id='about'>
    <img
      src={AboutImageHeader8}
      style={{
        width: "100vw",
        height: "22em",
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        objectFit: "cover",
        objectPosition: "center",
        objectPosition: "bottom",
      }}
    ></img>
  </div>
);
const AboutHeader9 = () => (
  <div id='about'>
    <img
      src={AboutImageHeader9}
      style={{
        width: "100vw",
        height: "22em",
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        objectFit: "cover",
        objectPosition: "center",
        objectPosition: "bottom",
      }}
    ></img>
  </div>
);
const Paragraph1 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 14,
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        marginTop: "0rem",
        color: "black",
      }}
    >
      Extract landfill gas from landfill and transforming the gas energy into
      electricity.
    </p>
  </div>
);
const AboutParagraph = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 14,
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        marginTop: "0rem",
        color: "black",
      }}
    >
      Knock-out pot installed on the gas ring. Main to seprate line. Condensate
      from the warm gas exstracted from the landfill.
    </p>
  </div>
);
const AboutParagraph1 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 14,
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        marginTop: "0rem",
        color: "black",
      }}
    >
      Drill digs to drill wills into landfill for the placing of gas extracting
      casing in drilled hole.
    </p>
  </div>
);
const AboutParagraph2 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 14,
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        marginTop: "0rem",
        color: "black",
      }}
    >
      90mm Distribution line conveying landfill gas from wells to gas balancing
      manifold box.
    </p>
  </div>
);

const AboutParagraph3 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 14,
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        marginTop: "0rem",
        color: "black",
      }}
    >
      90mm Distribution lines backfilled in landfill.
    </p>
  </div>
);
const AboutParagraph4 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 14,
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        marginTop: "0rem",
        color: "black",
      }}
    >
      Gas balancing manifold box installed on a landfill.
    </p>
  </div>
);
const AboutParagraph5 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 14,
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        marginTop: "0rem",
        color: "black",
      }}
    >
      Gas main line delivering landfill gas to the flaring unit. Burning
      hazardous methane landfill gas.
    </p>
  </div>
);
const AboutParagraph6 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 14,
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        marginTop: "0rem",
        color: "black",
      }}
    >
      Gas balancing manifold in process to BA connect to the gas ring main.
    </p>
  </div>
);
const AboutParagraph7 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 14,
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        marginTop: "0rem",
        color: "black",
      }}
    >
      Gas balancing manifold in process to BA connect to the gas ring main.
    </p>
  </div>
);
const AboutParagraph8 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 14,
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        marginTop: "0rem",
        color: "black",
      }}
    >
      Gas analised at plain unit.
    </p>
  </div>
);
const AboutParagraph9 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 14,
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        marginTop: "0rem",
        color: "black",
      }}
    >
      Landfill gas flarinng unit.
    </p>
  </div>
);

const items = [
  <Card
    className='item'
    data-value='1'
    style={{
      width: "22em",
      backgroundColor: "rgba(255, 255, 255, 0.85)",
      margin: "1em",

      padding: "0em",
      borderRadius: "1em",
      border: "none",
      textAlign: "center",
    }}
    // hoverable
  >
    <Meta
      title='LANDFILL GAS TO ELECTRICITY ENERGY'
      description={
        <span>
          <img
            className='products-img'
            style={{
              margin: "auto",
              marginTop: "1em",
              textAlign: "center",
              width: "17em",
            }}
            src={ProductImage1}
          ></img>
          <p
            className='Roboto'
            style={{
              fontWeight: 500,
              fontSize: 14,
              marginTop: "1.5rem",
              color: "black",
            }}
          >
            Description
          </p>
        </span>
      }
    />
    <div className='home-button'>
      <Link to='/Projects'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 1000,
            height: "2.2em",

            color: "#281562",
            border: "2px solid #281562",

            backgroundColor: "transparent",
            margin: "auto",
            marginTop: "2em",
            fontFamily: "Roboto",
          }}
          type='primary'
        >
          LEARN MORE
        </Button>
      </Link>
      <Link to='/ContactUs'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 500,
            height: "2.2em",

            color: "white",
            border: "2px solid #281562",

            backgroundColor: "#281562",
            margin: "auto",
            marginTop: "2em",
            marginLeft: "0.5em",
            fontFamily: "Roboto",
          }}
          type='primary'
        >
          CONTACT US
        </Button>
      </Link>
    </div>
  </Card>,
  <Card
    className='item'
    data-value='2'
    style={{
      width: "22em",
      backgroundColor: "rgba(255, 255, 255, 0.85)",
      margin: "1em",

      padding: "0em",
      borderRadius: "1em",
      border: "none",
      textAlign: "center",
    }}
    // hoverable
  >
    <Meta
      title='TELECOMMUNICATION MANHOLES'
      description={
        <span>
          <img
            className='products-img'
            style={{
              margin: "auto",
              marginTop: "1em",
              textAlign: "center",
              width: "17em",
            }}
            src={ProductImage2}
          ></img>
          <p
            className='Roboto'
            style={{
              fontWeight: 500,
              fontSize: 14,
              marginTop: "1.5rem",
              color: "black",
            }}
          >
            Description
          </p>
        </span>
      }
    />
    <div className='home-button'>
      <Link to='/Projects'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 1000,
            height: "2.2em",

            color: "#281562",
            border: "2px solid #281562",

            backgroundColor: "transparent",
            margin: "auto",
            marginTop: "2em",
            fontFamily: "Roboto",
          }}
          type='primary'
        >
          LEARN MORE
        </Button>
      </Link>
      <Link to='/ContactUs'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 500,
            height: "2.2em",

            color: "white",
            border: "2px solid #281562",

            backgroundColor: "#281562",
            margin: "auto",
            marginTop: "2em",
            marginLeft: "0.5em",
            fontFamily: "Roboto",
          }}
          type='primary'
        >
          CONTACT US
        </Button>
      </Link>
    </div>
  </Card>,
  <Card
    className='item'
    data-value='3'
    style={{
      width: "22em",
      backgroundColor: "rgba(255, 255, 255, 0.85)",
      margin: "1em",

      padding: "0em",
      borderRadius: "1em",
      border: "none",
      textAlign: "center",
    }}
    // hoverable
  >
    <Meta
      title='GAS LINES'
      description={
        <span>
          <img
            className='products-img'
            style={{
              margin: "auto",
              marginTop: "1em",
              textAlign: "center",
              width: "17em",
            }}
            src={ProductImage3}
          ></img>
          <p
            className='Roboto'
            style={{
              fontWeight: 500,
              fontSize: 14,
              marginTop: "1.5rem",
              color: "black",
            }}
          >
            Description
          </p>
        </span>
      }
    />
    <div className='home-button'>
      <Link to='/Projects'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 1000,
            height: "2.2em",

            color: "#281562",
            border: "2px solid #281562",

            backgroundColor: "transparent",
            margin: "auto",
            marginTop: "2em",
            fontFamily: "Roboto",
          }}
          type='primary'
        >
          LEARN MORE
        </Button>
      </Link>
      <Link to='/ContactUs'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 500,
            height: "2.2em",

            color: "white",
            border: "2px solid #281562",

            backgroundColor: "#281562",
            margin: "auto",
            marginTop: "2em",
            marginLeft: "0.5em",
            fontFamily: "Roboto",
          }}
          type='primary'
        >
          CONTACT US
        </Button>
      </Link>
    </div>
  </Card>,
  <Card
    className='item'
    data-value='4'
    style={{
      width: "22em",
      backgroundColor: "rgba(255, 255, 255, 0.85)",
      margin: "1em",

      padding: "0em",
      borderRadius: "1em",
      border: "none",
      textAlign: "center",
    }}
    // hoverable
  >
    <Meta
      title='HYDRAULIC TESTING'
      description={
        <span>
          <img
            className='products-img'
            style={{
              margin: "auto",
              marginTop: "1em",
              textAlign: "center",
              width: "17em",
            }}
            src={ProductImage4}
          ></img>
          <p
            className='Roboto'
            style={{
              fontWeight: 500,
              fontSize: 14,
              marginTop: "1.5rem",
              color: "black",
            }}
          >
            Description
          </p>
        </span>
      }
    />
    <div className='home-button'>
      <Link to='/Projects'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 1000,
            height: "2.2em",

            color: "#281562",
            border: "2px solid #281562",

            backgroundColor: "transparent",
            margin: "auto",
            marginTop: "2em",
            fontFamily: "Roboto",
          }}
          type='primary'
        >
          LEARN MORE
        </Button>
      </Link>
      <Link to='/ContactUs'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 500,
            height: "2.2em",

            color: "white",
            border: "2px solid #281562",

            backgroundColor: "#281562",
            margin: "auto",
            marginTop: "2em",
            marginLeft: "0.5em",
            fontFamily: "Roboto",
          }}
          type='primary'
        >
          CONTACT US
        </Button>
      </Link>
    </div>
  </Card>,
];
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};
const Home = () => (
  <div className='mobile-view'>
    <Header />
    <Col
      sm={24}
      md={6}
      style={{ margin: "auto", textAlign: "center", marginBottom: "2em" }}
    >
      <AboutHeader />
    </Col>
    <Row
      className='about-row'
      style={{
        textAlign: "center",
        margin: "auto",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        marginTop: "-1em",
        marginBottom: "2em",
      }}
    >
      <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "0em" }}>
        <Col sm={24} md={6} style={{ margin: "auto", marginTop: "1em" }}>
          <Header1 />
        </Col>
        <Col sm={0} md={1} />
        <Col sm={24} md={17} style={{ margin: "auto", marginTop: "2em" }}>
          <Paragraph1 />
        </Col>
      </Row>
      <img
        className='sketch1-img'
        src={Sketch3}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          opacity: 1,
          zIndex: -2,
        }}
      ></img>
      <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "1em" }}>
        <Col sm={24} md={6} style={{ marginTop: "1em" }}>
          <AboutHeader0 />
        </Col>
        <Col sm={0} md={1} />
        <Col
          sm={24}
          md={17}
          style={{ margin: "auto", margin: "auto", marginTop: "2em" }}
        >
          <AboutParagraph />
        </Col>
      </Row>
      <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "0em" }}>
        <Col sm={24} md={6} style={{ marginTop: "1em" }}>
          <AboutHeader1 />
        </Col>
        <Col sm={0} md={1} />
        <Col sm={24} md={17} style={{ margin: "auto", marginTop: "2em" }}>
          <AboutParagraph1 />
        </Col>
      </Row>
      <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "0em" }}>
        <Col sm={24} md={10} style={{ marginTop: "1em" }}>
          <AboutHeader2 />
        </Col>
        <Col sm={0} md={1} />
        <Col sm={24} md={13} style={{ margin: "auto", marginTop: "2em" }}>
          <AboutParagraph2 />
        </Col>
      </Row>
      <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "0em" }}>
        <Col sm={24} md={10} style={{ marginTop: "1em" }}>
          <AboutHeader3 />
        </Col>
        <Col sm={0} md={1} />
        <Col sm={24} md={13} style={{ margin: "auto", marginTop: "2em" }}>
          <AboutParagraph3 />
        </Col>
      </Row>
      <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "0em" }}>
        <Col sm={24} md={10} style={{ marginTop: "1em" }}>
          <AboutHeader4 />
        </Col>
        <Col sm={0} md={1} />
        <Col sm={24} md={13} style={{ margin: "auto", marginTop: "2em" }}>
          <AboutParagraph4 />
        </Col>
      </Row>
      <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "0em" }}>
        <Col sm={24} md={10} style={{ marginTop: "1em" }}>
          <AboutHeader5 />
        </Col>
        <Col sm={0} md={1} />
        <Col sm={24} md={13} style={{ margin: "auto", marginTop: "2em" }}>
          <AboutParagraph5 />
        </Col>
      </Row>
      <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "0em" }}>
        <Col sm={24} md={10} style={{ marginTop: "1em" }}>
          <AboutHeader6 />
        </Col>
        <Col sm={0} md={1} />
        <Col sm={24} md={13} style={{ margin: "auto", marginTop: "2em" }}>
          <AboutParagraph6 />
        </Col>
      </Row>
      <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "0em" }}>
        <Col sm={24} md={10} style={{ marginTop: "1em" }}>
          <AboutHeader7 />
        </Col>
        <Col sm={0} md={1} />
        <Col sm={24} md={13} style={{ margin: "auto", marginTop: "2em" }}>
          <AboutParagraph7 />
        </Col>
      </Row>
      <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "0em" }}>
        <Col sm={24} md={10} style={{ marginTop: "1em" }}>
          <AboutHeader8 />
        </Col>
        <Col sm={0} md={1} />
        <Col sm={24} md={13} style={{ margin: "auto", marginTop: "2em" }}>
          <AboutParagraph8 />
        </Col>
      </Row>
      <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "0em" }}>
        <Col sm={24} md={10} style={{ marginTop: "1em" }}>
          <AboutHeader9 />
        </Col>
        <Col sm={0} md={1} />
        <Col sm={24} md={13} style={{ margin: "auto", marginTop: "2em" }}>
          <AboutParagraph9 />
        </Col>
      </Row>
      <div className='home-button'>
        <Link to='/ContactUs'>
          <Button
            className='my-button'
            style={{
              borderRadius: 8,
              fontWeight: 500,
              height: "2.2em",

              color: "white",
              border: "2px solid #281562",

              backgroundColor: "#281562",
              margin: "auto",
              marginBottom: "2em",
              marginLeft: "0.5em",
              fontFamily: "Roboto",
            }}
            type='primary'
          >
            CONTACT US
          </Button>
        </Link>
      </div>
    </Row>
    {/*<Row
      style={{
        maxWidth: "100em",
        height: "35em",
        margin: "auto",
        margin:"auto",marginTop: "2em" ,
        backgroundImage: `url(${Sketch4})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <AliceCarousel
        infinite={true}
        disableDotsControls={true}
        disableButtonsControls={true}
        mouseTracking
        items={items}
        //responsive={responsive}
        //autoPlay={true}
        autoWidth={true}
        controlsStrategy='alternate'
      />
    </Row>*/}
    <Footer />
  </div>
);

export default Home;
