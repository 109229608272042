import React from "react";
import { Row, Col, Form, Input, Button, Card } from "antd";
import styled, { css, keyframes } from "styled-components";
import AboutImage from "../static/Home/background-ellipse1.png";
import AboutImageHeader from "../static/Home/landfill-image.png";
import AboutImageHeader1 from "../static/Home/landfill-image-1.png";
import AboutImageHeader2 from "../static/Home/landfill-image-2.png";
import AboutImageHeader3 from "../static/Home/landfill-image-3.png";
import AboutImageHeader4 from "../static/Home/landfill-image-4.png";
import AboutImageHeader5 from "../static/Home/landfill-image-5.png";
import AboutImageHeader6 from "../static/Home/landfill-image-6.png";
import AboutImageHeader7 from "../static/Home/landfill-image-7.png";
import AboutImageHeader8 from "../static/Home/landfill-image-8.png";
import AboutImageHeader9 from "../static/Home/landfill-image-9.png";

import ProductImage5 from "../static/Home/product-image-5.png";
import Sketch3 from "../static/Home/sketch-img-3.png";
import Sketch5 from "../static/Home/sketch-img-5.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import LandfillGassMobile from "./LandfillGassMobile";

import Header from "../components/Header-min";
import Footer from "../components/Footer";
import { ArrowRightOutlined } from "@ant-design/icons";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const { Meta } = Card;
const StyledCard = styled(Card)`
  .ant-card-meta-title {
    font-weight: 700 !important;
    font-size: 25px !important;
  }
`;

const AboutHeader = () => (
  <div id='about'>
    <Fade bottom>
      <p
        className='Roboto'
        style={{
          color: "white",
          fontWeight: 800,
          fontSize: "50px",
          marginBottom: "-0em",
          fontFamily: "Roboto",
        }}
      >
        OUR PROJECTS
      </p>
    </Fade>
  </div>
);
const AboutHeader0 = () => (
  <div id='about'>
    <img src={AboutImageHeader} style={{ width: "100%" }}></img>
  </div>
);
const AboutHeader1 = () => (
  <div id='about'>
    <img src={AboutImageHeader1} style={{ width: "100%" }}></img>
  </div>
);
const AboutHeader2 = () => (
  <div id='about'>
    <img src={AboutImageHeader2} style={{ width: "100%" }}></img>
  </div>
);
const AboutHeader3 = () => (
  <div id='about'>
    <img src={AboutImageHeader3} style={{ width: "100%" }}></img>
  </div>
);
const AboutHeader4 = () => (
  <div id='about'>
    <img src={AboutImageHeader4} style={{ width: "100%" }}></img>
  </div>
);
const AboutHeader5 = () => (
  <div id='about'>
    <img src={AboutImageHeader5} style={{ width: "100%" }}></img>
  </div>
);
const AboutHeader6 = () => (
  <div id='about'>
    <img src={AboutImageHeader6} style={{ width: "100%" }}></img>
  </div>
);
const AboutHeader7 = () => (
  <div id='about'>
    <img src={AboutImageHeader7} style={{ width: "100%" }}></img>
  </div>
);
const AboutHeader8 = () => (
  <div id='about'>
    <img src={AboutImageHeader8} style={{ width: "100%" }}></img>
  </div>
);
const AboutHeader9 = () => (
  <div id='about'>
    <img src={AboutImageHeader9} style={{ width: "100%" }}></img>
  </div>
);

const AboutParagraph = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Knock-out pot installed on the gas ring. Main to seprate line. Condensate
      from the warm gas exstracted from the landfill.
    </p>
  </div>
);
const AboutParagraph1 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Drill digs to drill wills into landfill for the placing of gas extracting
      casing in drilled hole.
    </p>
  </div>
);
const AboutParagraph2 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      90mm Distribution line conveying landfill gas from wells to gas balancing
      manifold box.
    </p>
  </div>
);
const AboutParagraph3 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Drill digs to drill wills into landfill for the placing of gas extracting
      casing in drilled hole.
    </p>
  </div>
);
const AboutParagraph4 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      90mm Distribution lines backfilled in landfill.
    </p>
  </div>
);
const AboutParagraph5 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Gas balancing manifold box installed on a landfill.
    </p>
  </div>
);
const AboutParagraph6 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Gas main line delivering landfill gas to the flaring unit. Burning
      hazardous methane landfill gas.
    </p>
  </div>
);
const AboutParagraph7 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Gas balancing manifold in process to BA connect to the gas ring main.
    </p>
  </div>
);
const AboutParagraph8 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        marginLeft: "2em",
        color: "black",
      }}
    >
      Gas analised at plain unit.
    </p>
  </div>
);
const AboutParagraph9 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        marginLeft: "-3em",
        color: "black",
      }}
    >
      Landfill gas flarinng unit.
    </p>
  </div>
);

const LandfillGass = () => (
  <div>
    <LandfillGassMobile />
    <div className='desktop-view'>
      <Header />
      <Row className='about-row' style={{ margin: "auto", maxWidth: "75em" }}>
        <img
          className='sketch1-img'
          src={Sketch5}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            opacity: 1,
            zIndex: -2,
          }}
        ></img>
        <Row style={{ marginTop: "-5em", textAlign: "left" }}>
          <Col
            sm={24}
            md={24}
            style={{ margin: "auto", marginTop: "0em", textAlign: "left" }}
          >
            <AboutHeader />
          </Col>
        </Row>
      </Row>
      <Row style={{ maxWidth: "100em", margin: "auto", marginTop: "2em" }}>
        <StyledCard
          className='item'
          data-value='1'
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            margin: "auto",
            padding: "1em",
            borderRadius: "1em",
            border: "none",
            maxWidth: "75em",
            width: "100%",
            textAlign: "center",
          }}
          // hoverable
        >
          <Meta
            title='LANDFILL GAS TO ELECTRICITY ENERGY'
            description={
              <span
                style={{
                  margin: "auto",
                  marginTop: "1em",
                  textAlign: "center",
                  width: "50%",
                }}
              >
                <div
                  className='products-img'
                  style={{
                    margin: "auto",
                    marginTop: "1em",
                    textAlign: "center",
                    width: "100%",
                    height: "25em",
                    borderRadius: "1em",
                    backgroundImage: `url(${ProductImage5})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  //src={ProductImage1}
                ></div>
                <p
                  className='Roboto'
                  style={{
                    fontWeight: 500,
                    fontSize: 22,
                    marginTop: "1.5rem",
                    color: "black",
                  }}
                >
                  Extract landfill gas from landfill and transforming the gas
                  energy into electricity.
                </p>
              </span>
            }
          />
          <img
            className='sketch1-img'
            src={Sketch3}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100vw",
              opacity: 1,
              zIndex: -2,
            }}
          ></img>
          <Row
            style={{
              maxWidth: "85em",
              margin: "auto",
              marginTop: "4em",
            }}
          >
            <Col sm={24} md={11} style={{ marginTop: "1em" }}>
              <AboutHeader0 />
            </Col>
            <Col sm={0} md={1} />
            <Col
              sm={24}
              md={11}
              style={{ marginTop: "4em", textAlign: "left" }}
            >
              <AboutParagraph />
            </Col>
          </Row>
          <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "-2em" }}>
            <Col
              sm={24}
              md={14}
              style={{ marginTop: "7em", textAlign: "left" }}
            >
              <AboutParagraph1 />
            </Col>
            <Col sm={0} md={1} />
            <Col sm={24} md={8} style={{ marginTop: "1em" }}>
              <AboutHeader1 />
            </Col>
          </Row>
          <Row
            style={{
              maxWidth: "85em",
              margin: "auto",
              marginTop: "-2em",
            }}
          >
            <Col sm={24} md={11} style={{ marginTop: "1em" }}>
              <AboutHeader2 />
            </Col>
            <Col sm={0} md={1} />
            <Col
              sm={24}
              md={11}
              style={{ marginTop: "6.5em", textAlign: "left" }}
            >
              <AboutParagraph2 />
            </Col>
          </Row>
          <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "-3em" }}>
            <Col
              sm={24}
              md={13}
              style={{ marginTop: "6em", textAlign: "left" }}
            >
              <AboutParagraph3 />
            </Col>
            <Col sm={0} md={1} />
            <Col sm={24} md={9} style={{ marginTop: "1em" }}>
              <AboutHeader3 />
            </Col>
          </Row>
          <Row
            style={{
              maxWidth: "85em",
              margin: "auto",
              marginTop: "1em",
            }}
          >
            <Col sm={24} md={11} style={{ marginTop: "1em" }}>
              <AboutHeader4 />
            </Col>
            <Col sm={0} md={1} />
            <Col
              sm={24}
              md={11}
              style={{ marginTop: "5.5em", textAlign: "left" }}
            >
              <AboutParagraph4 />
            </Col>
          </Row>
          <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "-5em" }}>
            <Col
              sm={24}
              md={13}
              style={{ marginTop: "9.5em", textAlign: "left" }}
            >
              <AboutParagraph5 />
            </Col>
            <Col sm={0} md={1} />
            <Col sm={24} md={9} style={{ marginTop: "4em" }}>
              <AboutHeader5 />
            </Col>
          </Row>
          <Row
            style={{
              maxWidth: "85em",
              margin: "auto",
              marginTop: "-1em",
            }}
          >
            <Col sm={24} md={11} style={{ marginTop: "1em" }}>
              <AboutHeader6 />
            </Col>
            <Col sm={0} md={1} />
            <Col
              sm={24}
              md={11}
              style={{ marginTop: "6em", textAlign: "left" }}
            >
              <AboutParagraph6 />
            </Col>
          </Row>
          <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "0em" }}>
            <Col
              sm={24}
              md={13}
              style={{ marginTop: "4em", textAlign: "left" }}
            >
              <AboutParagraph7 />
            </Col>
            <Col sm={0} md={1} />
            <Col sm={24} md={9} style={{ marginTop: "1em" }}>
              <AboutHeader7 />
            </Col>
          </Row>
          <Row
            style={{
              maxWidth: "85em",
              margin: "auto",
              marginTop: "-1em",
            }}
          >
            <Col sm={24} md={9} style={{ marginTop: "1em" }}>
              <AboutHeader8 />
            </Col>
            <Col sm={0} md={1} />
            <Col sm={24} md={13} style={{ marginTop: "5em", textAlign: "" }}>
              <AboutParagraph8 />
            </Col>
          </Row>
          <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "-2em" }}>
            <Col sm={24} md={11} style={{ marginTop: "3em", textAlign: "" }}>
              <AboutParagraph9 />
            </Col>
            <Col sm={0} md={1} />
            <Col sm={24} md={11} style={{ marginTop: "-15em" }}>
              <AboutHeader9 />
            </Col>
          </Row>

          <div className='home-button'>
            <Link to='/ContactUs'>
              <Button
                className='my-button'
                style={{
                  borderRadius: 8,
                  fontWeight: 700,
                  height: "2.3em",
                  width: "8.5em",
                  color: "white",
                  border: "3px solid #281562",
                  fontSize: "17px",
                  backgroundColor: "#281562",
                  marginTop: "3em",
                  float: "right",
                  fontFamily: "Roboto",
                }}
                type='primary'
              >
                CONTACT US
              </Button>
            </Link>
          </div>
        </StyledCard>
      </Row>
      <Footer />
    </div>
  </div>
);

export default LandfillGass;
