import React from "react";
import { Row, Col, Form, Input, Button, Card } from "antd";
import styled, { css, keyframes } from "styled-components";
import AboutImage from "../static/Home/background-ellipse1.png";
import AboutImageHeader from "../static/Home/about-image.png";
import AboutImageHeader1 from "../static/Home/about-image-1.png";
import AboutImageHeader2 from "../static/Home/about-image-2.png";
import AboutImageHeader3 from "../static/Home/about-image-3.png";
import ProductImage1 from "../static/Home/product-image-1.png";
import ProductImage2 from "../static/Home/product-image-2.png";
import ProductImage3 from "../static/Home/product-image-3.png";
import ProductImage4 from "../static/Home/product-image-4.png";
import ProductImage5 from "../static/Home/product-image-5.png";
import Sketch3 from "../static/Home/sketch-img-3.png";
import Sketch5 from "../static/Home/sketch-img-5.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ProjectsMobile from "./ProjectsMobile";

import Header from "../components/Header-min";
import Footer from "../components/Footer";
import { ArrowRightOutlined } from "@ant-design/icons";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const { Meta } = Card;
const StyledCard = styled(Card)`
  .ant-card-meta-title {
    font-weight: 700 !important;
    font-size: 25px !important;
  }
`;

const AboutHeader = () => (
  <div id='about'>
    <Fade bottom>
      <p
        className='Roboto'
        style={{
          color: "white",
          fontWeight: 800,
          fontSize: "50px",
          marginBottom: "-0em",
          fontFamily: "Roboto",
        }}
      >
        OUR PROJECTS
      </p>
    </Fade>
  </div>
);

const Project = () => (
  <div>
    <ProjectsMobile />
    <div className='desktop-view'>
      <Header />
      <Row className='about-row' style={{ margin: "auto", maxWidth: "75em" }}>
        {" "}
        <img
          className='sketch1-img'
          src={Sketch5}
          style={{
            position: "absolute",
            top: 0,
            height: "100%",
            left: 0,
            width: "100vw",
            opacity: 1,
            zIndex: -2,
          }}
        ></img>
        <Row style={{ marginTop: "-5em", textAlign: "left" }}>
          <Col
            sm={24}
            md={24}
            style={{ margin: "auto", marginTop: "0em", textAlign: "left" }}
          >
            <AboutHeader />
          </Col>{" "}
        </Row>{" "}
      </Row>{" "}
      <Row style={{ maxWidth: "100em", margin: "auto", marginTop: "2em" }}>
        <StyledCard
          className='item'
          data-value='1'
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            margin: "auto",
            padding: "1em",
            borderRadius: "1em",
            border: "none",
            maxWidth: "75em",
            width: "100%",
            textAlign: "center",
          }}
          // hoverable
        >
          <Meta
            title='LANDFILL GAS TO ELECTRICITY ENERGY'
            description={
              <span
                style={{
                  margin: "auto",
                  marginTop: "1em",
                  textAlign: "center",
                  width: "50%",
                }}
              >
                <div
                  className='products-img'
                  style={{
                    margin: "auto",
                    marginTop: "1em",
                    textAlign: "center",
                    width: "100%",
                    height: "25em",
                    borderRadius: "1em",
                    backgroundImage: `url(${ProductImage5})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  //src={ProductImage1}
                ></div>
                <p
                  className='Roboto'
                  style={{
                    fontWeight: 500,
                    fontSize: 18,
                    marginTop: "1.5rem",
                    color: "black",
                  }}
                >
                  Extract landfill gas from landfill and transforming the gas
                  energy into electricity.
                </p>{" "}
              </span>
            }
          />
          <div className='home-button'>
            <Link to='/ContactUs'>
              <Button
                className='my-button'
                style={{
                  borderRadius: 8,
                  fontWeight: 700,
                  height: "2.3em",
                  width: "8.5em",
                  color: "white",
                  border: "3px solid #281562",
                  fontSize: "17px",
                  backgroundColor: "#281562",
                  marginTop: "1em",
                  float: "right",
                  fontFamily: "Roboto",
                }}
                type='primary'
              >
                CONTACT US
              </Button>
            </Link>
            <Link to='/LandfillGass'>
              <Button
                className='my-button'
                style={{
                  borderRadius: 8,
                  fontWeight: 1000,
                  height: "2.3em",
                  width: "8.5em",
                  color: "#281562",
                  border: "3px solid #281562",
                  fontSize: "17px",
                  backgroundColor: "transparent",
                  marginTop: "1em",
                  marginRight: "1em",
                  fontFamily: "Roboto",
                  float: "right",
                }}
                type='primary'
              >
                LEARN MORE
              </Button>
            </Link>
          </div>
        </StyledCard>
      </Row>
      {/*<Row style={{ maxWidth: "100em", margin: "auto", marginTop: "2em" }}>
        <StyledCard
          className='item'
          data-value='1'
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            margin: "auto",
            padding: "1em",
            borderRadius: "1em",
            border: "none",
            maxWidth: "75em",
            width: "100%",
            textAlign: "center",
          }}
          // hoverable
        >
          <Meta
            title='IRRIGATION FLOATING PUMPS'
            description={
              <span
                style={{
                  margin: "auto",
                  marginTop: "1em",
                  textAlign: "center",
                  width: "50%",
                }}
              >
                <div
                  className='products-img'
                  style={{
                    margin: "auto",
                    marginTop: "1em",
                    textAlign: "center",
                    width: "70%",
                    height: "25em",
                    borderRadius: "1em",
                    backgroundImage: `url(${ProductImage1})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  //src={ProductImage1}
                ></div>
                <p
                  className='Roboto'
                  style={{
                    fontWeight: 500,
                    fontSize: 18,
                    marginTop: "1.5rem",
                    color: "black",
                  }}
                >
                  Description
                </p>{" "}
              </span>
            }
          />
          <div className='home-button'>
            <Link to='/ContactUs'>
              <Button
                className='my-button'
                style={{
                  borderRadius: 8,
                  fontWeight: 700,
                  height: "2.3em",
                  width: "8.5em",
                  color: "white",
                  border: "3px solid #281562",
                  fontSize: "17px",
                  backgroundColor: "#281562",
                  marginTop: "1em",
                  float: "right",
                  fontFamily: "Roboto",
                }}
                type='primary'
              >
                CONTACT US
              </Button>
            </Link>
          </div>
        </StyledCard>
      </Row>
      <Row style={{ maxWidth: "100em", margin: "auto", marginTop: "2em" }}>
        <StyledCard
          className='item'
          data-value='1'
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            margin: "auto",
            padding: "1em",
            borderRadius: "1em",
            border: "none",
            maxWidth: "75em",
            width: "100%",
            textAlign: "center",
          }}
          // hoverable
        >
          <Meta
            title='TELECOMMUNICATION MANHOLES'
            description={
              <span
                style={{
                  margin: "auto",
                  marginTop: "1em",
                  textAlign: "center",
                  width: "50%",
                }}
              >
                <div
                  className='products-img'
                  style={{
                    margin: "auto",
                    marginTop: "1em",
                    textAlign: "center",
                    width: "70%",
                    height: "25em",
                    borderRadius: "1em",
                    backgroundImage: `url(${ProductImage2})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  //src={ProductImage1}
                ></div>
                <p
                  className='Roboto'
                  style={{
                    fontWeight: 500,
                    fontSize: 18,
                    marginTop: "1.5rem",
                    color: "black",
                  }}
                >
                  Description
                </p>{" "}
              </span>
            }
          />
          <div className='home-button'>
            <Link to='/ContactUs'>
              <Button
                className='my-button'
                style={{
                  borderRadius: 8,
                  fontWeight: 700,
                  height: "2.3em",
                  width: "8.5em",
                  color: "white",
                  border: "3px solid #281562",
                  fontSize: "17px",
                  backgroundColor: "#281562",
                  marginTop: "1em",
                  float: "right",
                  fontFamily: "Roboto",
                }}
                type='primary'
              >
                CONTACT US
              </Button>
            </Link>
          </div>
        </StyledCard>
      </Row>
      <Row
        id='irrigationfloatingpumps'
        style={{ maxWidth: "100em", margin: "auto", marginTop: "2em" }}
      >
        <StyledCard
          className='item'
          data-value='1'
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            margin: "auto",
            padding: "1em",
            borderRadius: "1em",
            border: "none",
            maxWidth: "75em",
            width: "100%",
            textAlign: "center",
          }}
          // hoverable
        >
          <Meta
            title='GAS LINES'
            description={
              <span
                style={{
                  margin: "auto",
                  marginTop: "1em",
                  textAlign: "center",
                  width: "50%",
                }}
              >
                <div
                  className='products-img'
                  style={{
                    margin: "auto",
                    marginTop: "1em",
                    textAlign: "center",
                    width: "70%",
                    height: "25em",
                    borderRadius: "1em",
                    backgroundImage: `url(${ProductImage3})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  //src={ProductImage1}
                ></div>
                <p
                  className='Roboto'
                  style={{
                    fontWeight: 500,
                    fontSize: 18,
                    marginTop: "1.5rem",
                    color: "black",
                  }}
                >
                  Description
                </p>{" "}
              </span>
            }
          />
          <div className='home-button'>
            <Link to='/ContactUs'>
              <Button
                className='my-button'
                style={{
                  borderRadius: 8,
                  fontWeight: 700,
                  height: "2.3em",
                  width: "8.5em",
                  color: "white",
                  border: "3px solid #281562",
                  fontSize: "17px",
                  backgroundColor: "#281562",
                  marginTop: "1em",
                  float: "right",
                  fontFamily: "Roboto",
                }}
                type='primary'
              >
                CONTACT US
              </Button>
            </Link>
          </div>
        </StyledCard>
      </Row>
      <Row style={{ maxWidth: "100em", margin: "auto", marginTop: "2em" }}>
        <StyledCard
          className='item'
          data-value='1'
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            margin: "auto",
            padding: "1em",
            borderRadius: "1em",
            border: "none",
            maxWidth: "75em",
            width: "100%",
            textAlign: "center",
          }}
          // hoverable
        >
          <Meta
            title='HYDRAULIC TESTING'
            description={
              <span
                style={{
                  margin: "auto",
                  marginTop: "1em",
                  textAlign: "center",
                  width: "50%",
                }}
              >
                <div
                  className='products-img'
                  style={{
                    margin: "auto",
                    marginTop: "1em",
                    textAlign: "center",
                    width: "70%",
                    height: "25em",
                    borderRadius: "1em",
                    backgroundImage: `url(${ProductImage4})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  //src={ProductImage1}
                ></div>
                <p
                  className='Roboto'
                  style={{
                    fontWeight: 500,
                    fontSize: 18,
                    marginTop: "1.5rem",
                    color: "black",
                  }}
                >
                  Description
                </p>{" "}
              </span>
            }
          />
          <div className='home-button'>
            <Link to='/ContactUs'>
              <Button
                className='my-button'
                style={{
                  borderRadius: 8,
                  fontWeight: 700,
                  height: "2.3em",
                  width: "8.5em",
                  color: "white",
                  border: "3px solid #281562",
                  fontSize: "17px",
                  backgroundColor: "#281562",
                  marginTop: "1em",
                  float: "right",
                  fontFamily: "Roboto",
                }}
                type='primary'
              >
                CONTACT US
              </Button>
            </Link>
          </div>
        </StyledCard>
      </Row>*/}
      <Footer />
    </div>
  </div>
);

export default Project;
