import React from "react";
import { Row, Col, Button, Card } from "antd";
import AboutImageHeader from "../static/Home/about-image.png";
import styled from "styled-components";
import AboutImageHeader1 from "../static/Home/about-image-1.png";
import AboutImageHeader2 from "../static/Home/about-image-2.png";
import AboutImageHeader3 from "../static/Home/about-image-3.png";
import ProductImage1 from "../static/Home/product-image-1.png";
import ProductImage2 from "../static/Home/product-image-2.png";
import ProductImage3 from "../static/Home/product-image-3.png";
import ProductImage4 from "../static/Home/product-image-4.png";
import ProductImage5 from "../static/Home/product-image-5.png";
import Sketch3 from "../static/Home/sketch-img-3.png";
import Sketch4 from "../static/Home/sketch-img-4.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Header from "../components/Header-min";
import Footer from "../components/Footer";
import AboutUsMobile from "./AboutUsMobile";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const { Meta } = Card;
const StyledCard = styled(Card)`
  .ant-card-meta-title {
    font-weight: 700 !important;
    font-size: 25px !important;
  }
`;
const AboutHeader = () => (
  <div id='about'>
    <p
      className='Roboto'
      style={{
        fontWeight: 800,
        fontSize: "50px",
        marginBottom: "-0em",
        fontFamily: "Roboto",
      }}
    >
      ABOUT US
    </p>
    <img src={AboutImageHeader} style={{ width: "100%" }}></img>{" "}
  </div>
);
const AboutHeader1 = () => (
  <div id='about'>
    <img src={AboutImageHeader1} style={{ width: "100%" }}></img>{" "}
  </div>
);
const AboutHeader2 = () => (
  <div id='about'>
    <img src={AboutImageHeader2} style={{ width: "100%" }}></img>{" "}
  </div>
);
const AboutHeader3 = () => (
  <div id='about'>
    <img src={AboutImageHeader3} style={{ width: "100%" }}></img>
  </div>
);
const AboutParagraph = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Customised Plastic Products CC is a micro enterprise specializing in HDPE
      and PP sheet and pipe engineering. The company was established in June
      2006 by the owner and Managing Director. Customised Plastic Products
      fabricate various products in their fully equipped factory 30km North of
      Pretoria. In addition they perform an onsite welding and installation
      service. Customised Plastic Products CC is a level 4 B-BBEE contributor
      and has a 100% B-BBEE procurement recognition level.
    </p>{" "}
  </div>
);
const AboutParagraph1 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Customised Plastic Products (CPP) is also a founding member of IFPA
      (Installation and Fabrication Plastic Pipe Association) an affiliate
      association to SAPPMA (South African Plastic Pipe Manufacturers
      Association). The main objective of IFPA and its member companies is to
      ensure consistent high quality installations and fabrications. To this end
      all member companies commit to abide to a code of ethics to ensure
      quality.
    </p>{" "}
  </div>
);
const AboutParagraph2 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      CPP has a wealth of experience in the field of fabricating and
      installations in high risk dolomitic areas. All welding teams are
      qualified and certified to meet the stringent requirements set out by the
      Department of Public Works. Processes employed, equipment and materials
      used is well maintained and meet both International and South African
      requirements. CPP have successfully completed the supply and Installation
      of Electrical-; Storm water- and Sewer
    </p>{" "}
  </div>
);
const AboutParagraph2Extra = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "3rem",
        color: "black",
      }}
    >
      Manholes for the Waterkloof Air Force Base Presidential Suite and Main
      Runway rehabilitation project. CPP was subsequently also appointed to
      perform storm water pipe welding on the Secondary runway upgrade project.
      Other DPW projects successfully completed are: Thaba Tswane Nurses College
      Storm water Pipe and Manholes, Westonaria Court & 68 Air School
      rehabilitation of wet services and supply pre-fabricated HDPE manholes.
    </p>{" "}
  </div>
);
const AboutParagraph3 = () => (
  <div>
    <p
      className='Roboto'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Various Mining - and Industrial projects have also been undertaken,
      typical examples are design and fabricate acid tanks for surface treatment
      plants and HDPE Vacuum filtration systems for mine processing plants,
      meeting high quality requirements and close tolerances.
      <br />
      CPP is conversant and equipped to perform all types of welding e.g.
      Butt-welding (pipe sizes 40mm - 500 OD); Electro-fusion welding (20mm to
      1200mm OD) ; Socket-fusion- and Extrusion welding. In accordance with DPW
      high risk dolomitic requirements Butt-Welding equipment meet the
      requirements of SANS 1671-1. In addition to site welding CPP also offers a
      pipe testing service both hydrostatic and Air testing in accordance with
      SABS 1200.
    </p>{" "}
  </div>
);
const items = [
  <Fade bottom>
    <StyledCard
      className='item'
      data-value='1'
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.85)",
        margin: "1em",
        padding: "1em",
        borderRadius: "1em",
        border: "none",
        textAlign: "center",
      }}
      // hoverable
    >
      <Meta
        title='LANDFILL GAS TO ELECTRICITY ENERGY'
        description={
          <span>
            <img
              className='products-img'
              style={{
                margin: "auto",
                width: "100%",
                marginTop: "1em",
                textAlign: "center",
              }}
              src={ProductImage5}
            ></img>
            <p
              className='Roboto'
              style={{
                fontWeight: 500,
                fontSize: 18,
                marginTop: "1.5rem",
                color: "black",
              }}
            >
              Extract landfill gas from landfill and transforming the gas energy
              into electricity.
            </p>{" "}
          </span>
        }
      />
      <div className='home-button'>
        <Link to='/LandfillGass'>
          <Button
            className='my-button'
            style={{
              borderRadius: 8,
              fontWeight: 1000,
              height: "2.3em",
              width: "8.5em",
              color: "#281562",
              border: "3px solid #281562",
              fontSize: "17px",
              backgroundColor: "transparent",
              marginTop: "1em",
              fontFamily: "Roboto",
            }}
            type='primary'
          >
            LEARN MORE
          </Button>
        </Link>{" "}
        <Link to='/ContactUs'>
          <Button
            className='my-button'
            style={{
              borderRadius: 8,
              fontWeight: 500,
              height: "2.3em",
              width: "8.5em",
              color: "white",
              border: "3px solid #281562",
              fontSize: "17px",
              backgroundColor: "#281562",
              marginTop: "1em",
              marginLeft: "0.5em",
              fontFamily: "Roboto",
            }}
            type='primary'
          >
            CONTACT US
          </Button>
        </Link>
      </div>
    </StyledCard>
  </Fade>,
  //<Fade bottom>
  //  <Card
  //    className='item'
  //    data-value='1'
  //    style={{
  //      backgroundColor: "rgba(255, 255, 255, 0.85)",
  //      margin: "1em",
  //      padding: "1em",
  //      borderRadius: "1em",
  //      border: "none",
  //      textAlign: "center",
  //    }}
  //    // hoverable
  //  >
  //    <Meta
  //      title='IRRIGATION FLOATING PUMPS'
  //      description={
  //        <span>
  //          <img
  //            className='products-img'
  //            style={{
  //              margin: "auto",
  //              marginTop: "1em",
  //              textAlign: "center",
  //              width: "23em",
  //            }}
  //            src={ProductImage1}
  //          ></img>
  //          <p
  //            className='Roboto'
  //            style={{
  //              fontWeight: 500,
  //              fontSize: 18,
  //              marginTop: "1.5rem",
  //              color: "black",
  //            }}
  //          >
  //            Description
  //          </p>{" "}
  //        </span>
  //      }
  //    />
  //    <div className='home-button'>
  //      <Link to='/Projects'>
  //        <Button
  //          className='my-button'
  //          style={{
  //            borderRadius: 8,
  //            fontWeight: 1000,
  //            height: "2.3em",
  //            width: "8.5em",
  //            color: "#281562",
  //            border: "3px solid #281562",
  //            fontSize: "17px",
  //            backgroundColor: "transparent",
  //            marginTop: "1em",
  //            fontFamily: "Roboto",
  //          }}
  //          type='primary'
  //        >
  //          LEARN MORE
  //        </Button>
  //      </Link>{" "}
  //      <Link to='/ContactUs'>
  //        <Button
  //          className='my-button'
  //          style={{
  //            borderRadius: 8,
  //            fontWeight: 700,
  //            height: "2.3em",
  //            width: "8.5em",
  //            color: "white",
  //            border: "3px solid #281562",
  //            fontSize: "17px",
  //            backgroundColor: "#281562",
  //            marginTop: "1em",
  //            marginLeft: "0.5em",
  //            fontFamily: "Roboto",
  //          }}
  //          type='primary'
  //        >
  //          CONTACT US
  //        </Button>
  //      </Link>
  //    </div>
  //  </Card>
  //</Fade>,
  //<Fade bottom>
  //  <Card
  //    className='item'
  //    data-value='2'
  //    style={{
  //      backgroundColor: "rgba(255, 255, 255, 0.85)",
  //      margin: "1em",
  //      padding: "1em",
  //      borderRadius: "1em",
  //      border: "none",
  //      textAlign: "center",
  //    }}
  //    // hoverable
  //  >
  //    <Meta
  //      title='TELECOMMUNICATION MANHOLES'
  //      description={
  //        <span>
  //          <img
  //            className='products-img'
  //            style={{
  //              margin: "auto",
  //              marginTop: "1em",
  //              textAlign: "center",
  //              width: "23em",
  //            }}
  //            src={ProductImage2}
  //          ></img>
  //          <p
  //            className='Roboto'
  //            style={{
  //              fontWeight: 500,
  //              fontSize: 18,
  //              marginTop: "1.5rem",
  //              color: "black",
  //            }}
  //          >
  //            Description
  //          </p>{" "}
  //        </span>
  //      }
  //    />
  //    <div className='home-button'>
  //      <Link to='/Projects'>
  //        <Button
  //          className='my-button'
  //          style={{
  //            borderRadius: 8,
  //            fontWeight: 1000,
  //            height: "2.3em",
  //            width: "8.5em",
  //            color: "#281562",
  //            border: "3px solid #281562",
  //            fontSize: "17px",
  //            backgroundColor: "transparent",
  //            marginTop: "1em",
  //            fontFamily: "Roboto",
  //          }}
  //          type='primary'
  //        >
  //          LEARN MORE
  //        </Button>
  //      </Link>{" "}
  //      <Link to='/ContactUs'>
  //        <Button
  //          className='my-button'
  //          style={{
  //            borderRadius: 8,
  //            fontWeight: 700,
  //            height: "2.3em",
  //            width: "8.5em",
  //            color: "white",
  //            border: "3px solid #281562",
  //            fontSize: "17px",
  //            backgroundColor: "#281562",
  //            marginTop: "1em",
  //            marginLeft: "0.5em",
  //            fontFamily: "Roboto",
  //          }}
  //          type='primary'
  //        >
  //          CONTACT US
  //        </Button>
  //      </Link>
  //    </div>
  //  </Card>
  //</Fade>,
  //<Fade bottom>
  //  <Card
  //    className='item'
  //    data-value='3'
  //    style={{
  //      backgroundColor: "rgba(255, 255, 255, 0.85)",
  //      margin: "1em",
  //      padding: "1em",
  //      borderRadius: "1em",
  //      border: "none",
  //      textAlign: "center",
  //    }}
  //    // hoverable
  //  >
  //    <Meta
  //      title='GAS LINES'
  //      description={
  //        <span>
  //          <img
  //            className='products-img'
  //            style={{
  //              margin: "auto",
  //              marginTop: "1em",
  //              textAlign: "center",
  //              width: "23em",
  //            }}
  //            src={ProductImage3}
  //          ></img>
  //          <p
  //            className='Roboto'
  //            style={{
  //              fontWeight: 500,
  //              fontSize: 18,
  //              marginTop: "1.5rem",
  //              color: "black",
  //            }}
  //          >
  //            Description
  //          </p>{" "}
  //        </span>
  //      }
  //    />
  //    <div className='home-button'>
  //      <Link to='/Projects'>
  //        <Button
  //          className='my-button'
  //          style={{
  //            borderRadius: 8,
  //            fontWeight: 1000,
  //            height: "2.3em",
  //            width: "8.5em",
  //            color: "#281562",
  //            border: "3px solid #281562",
  //            fontSize: "17px",
  //            backgroundColor: "transparent",
  //            marginTop: "1em",
  //            fontFamily: "Roboto",
  //          }}
  //          type='primary'
  //        >
  //          LEARN MORE
  //        </Button>
  //      </Link>{" "}
  //      <Link to='/ContactUs'>
  //        <Button
  //          className='my-button'
  //          style={{
  //            borderRadius: 8,
  //            fontWeight: 700,
  //            height: "2.3em",
  //            width: "8.5em",
  //            color: "white",
  //            border: "3px solid #281562",
  //            fontSize: "17px",
  //            backgroundColor: "#281562",
  //            marginTop: "1em",
  //            marginLeft: "0.5em",
  //            fontFamily: "Roboto",
  //          }}
  //          type='primary'
  //        >
  //          CONTACT US
  //        </Button>
  //      </Link>
  //    </div>
  //  </Card>
  //</Fade>,
  //<Fade bottom>
  //  <Card
  //    className='item'
  //    data-value='4'
  //    style={{
  //      backgroundColor: "rgba(255, 255, 255, 0.85)",
  //      margin: "1em",
  //      padding: "1em",
  //      borderRadius: "1em",
  //      border: "none",
  //      textAlign: "center",
  //    }}
  //    // hoverable
  //  >
  //    <Meta
  //      title='HYDRAULIC TESTING'
  //      description={
  //        <span>
  //          <img
  //            className='products-img'
  //            style={{
  //              margin: "auto",
  //              marginTop: "1em",
  //              textAlign: "center",
  //              width: "23em",
  //            }}
  //            src={ProductImage4}
  //          ></img>
  //          <p
  //            className='Roboto'
  //            style={{
  //              fontWeight: 500,
  //              fontSize: 18,
  //              marginTop: "1.5rem",
  //              color: "black",
  //            }}
  //          >
  //            Description
  //          </p>{" "}
  //        </span>
  //      }
  //    />
  //    <div className='home-button'>
  //      <Link to='/Projects'>
  //        <Button
  //          className='my-button'
  //          style={{
  //            borderRadius: 8,
  //            fontWeight: 1000,
  //            height: "2.3em",
  //            width: "8.5em",
  //            color: "#281562",
  //            border: "3px solid #281562",
  //            fontSize: "17px",
  //            backgroundColor: "transparent",
  //            marginTop: "1em",
  //            fontFamily: "Roboto",
  //          }}
  //          type='primary'
  //        >
  //          LEARN MORE
  //        </Button>
  //      </Link>{" "}
  //      <Link to='/ContactUs'>
  //        <Button
  //          className='my-button'
  //          style={{
  //            borderRadius: 8,
  //            fontWeight: 700,
  //            height: "2.3em",
  //            width: "8.5em",
  //            color: "white",
  //            border: "3px solid #281562",
  //            fontSize: "17px",
  //            backgroundColor: "#281562",
  //            marginTop: "1em",
  //            marginLeft: "0.5em",
  //            fontFamily: "Roboto",
  //          }}
  //          type='primary'
  //        >
  //          CONTACT US
  //        </Button>
  //      </Link>
  //    </div>
  //  </Card>
  //</Fade>,
];
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};
const Home = () => (
  <div>
    <AboutUsMobile />
    <div className='desktop-view'>
      <Header />
      <Row
        className='about-row'
        style={{
          margin: "auto",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          marginTop: "-1em",
          padding: "1.5em",
        }}
      >
        {" "}
        <img
          className='sketch1-img'
          src={Sketch3}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            opacity: 1,
            zIndex: -2,
          }}
        ></img>
        <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "1em" }}>
          <Col sm={24} md={6} style={{ marginTop: "1em" }}>
            <AboutHeader />
          </Col>{" "}
          <Col sm={0} md={1} />
          <Col sm={24} md={17} style={{ marginTop: "8em" }}>
            <AboutParagraph />
          </Col>{" "}
        </Row>{" "}
        <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "-5em" }}>
          <Col sm={24} md={17} style={{ marginTop: "6em" }}>
            <AboutParagraph1 />
          </Col>{" "}
          <Col sm={0} md={1} />{" "}
          <Col sm={24} md={6} style={{ marginTop: "1em" }}>
            <AboutHeader1 />
          </Col>{" "}
        </Row>{" "}
        <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "-3em" }}>
          <Col sm={24} md={10} style={{ marginTop: "1em" }}>
            <AboutHeader2 />
          </Col>{" "}
          <Col sm={0} md={1} />
          <Col sm={24} md={13} style={{ marginTop: "4.5em" }}>
            <AboutParagraph2 />
          </Col>{" "}
        </Row>{" "}
        <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "-7em" }}>
          <Col sm={24} md={234} style={{ marginTop: "6em" }}>
            <AboutParagraph2Extra />
          </Col>{" "}
        </Row>{" "}
        <Row
          style={{
            maxWidth: "85em",
            margin: "auto",
            marginTop: "-2em",
            paddingBottom: "2em",
          }}
        >
          <Col sm={24} md={17} style={{ marginTop: "6em" }}>
            <AboutParagraph3 />
          </Col>{" "}
          <Col sm={0} md={1} />{" "}
          <Col sm={24} md={6} style={{ marginTop: "4em" }}>
            <AboutHeader3 />
          </Col>{" "}
        </Row>{" "}
      </Row>{" "}
      <Row style={{ maxWidth: "100em", margin: "auto", marginTop: "6em" }}>
        <img
          className='contact-image'
          style={{
            marginTop: "-30em",
            width: "100vw",

            left: 0,
            zIndex: -1,
            position: "absolute",
          }}
          src={Sketch4}
        ></img>
        <AliceCarousel
          infinite={false}
          disableDotsControls={true}
          disableButtonsControls={true}
          mouseTracking
          items={items}
          responsive={responsive}
          controlsStrategy='alternate'
        />
      </Row>
      <Footer />
    </div>
  </div>
);

export default Home;
