import React, { useState } from "react";
import { PageHeader, Popover, BackTop } from "antd";
import { Link } from "react-router-dom";
import { slide as Burger, Item } from "burger-menu";
import "burger-menu/lib/index.css";

import MainLogo from "../static/Home/main-logo.png";
import "antd/dist/antd.css";

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };
  function scrollFunction() {
    document.documentElement.scrollTop = 0;
    setVisible(false);
  }

  const content = (
    <div>
      <Link to='/' onClick={scrollFunction}>
        <Item itemKey={"a"} text={"HOME"}></Item>
      </Link>
      <Link to='/AboutUs'>
        {" "}
        <Item itemKey={"b"} text={"ABOUT US"}></Item>
      </Link>
      <Link to='/Projects'>
        {" "}
        <Item itemKey={"c"} text={"PROJECTS"}></Item>
      </Link>
      <Link to='/ContactUs'>
        {" "}
        <Item itemKey={"d"} text={"CONTACT US"}></Item>
      </Link>
      {/*<SubMenu title="Union Management">
            <Item itemKey={"notice"} text={"Announcement"}></Item>
            <Item itemKey={"union"} text={"Union Inquiries"}></Item>
            <Item itemKey={"entry"} text={"Entry information"}></Item>
          </SubMenu>*/}
    </div>
  );
  return (
    <>
      {" "}
      <Popover
        className='burger-container'
        placement='bottomRight'
        content={content}
        trigger='click'
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <div>
          <div id='nav-toggle'>
            <span></span>
          </div>
        </div>{" "}
      </Popover>
      <Burger
        right
        className='burger-menu'
        isOpen={isOpen}
        selectedKey={"entry"}
        onClose={() => setIsOpen(false)}
      ></Burger>
    </>
  );
};
const MainHeader = () => (
  <div id='Header' className='site-page-header-ghost-wrapper'>
    <PageHeader
      style={{
        backgroundColor: "transparent",
        maxWidth: "90em",
        height: "10em",
        width: "90vw",
        margin: "auto",
        padding: 0,
      }}
      ghost={false}
      //  onBack={() => window.history.back()}
      title={
        <div>
          <Link to='/'>
            <img
              className='header-logo'
              style={{ width: "12em" }}
              src={MainLogo}
            ></img>{" "}
          </Link>
          <div class='main'>
            <div class='circle'></div>
          </div>
        </div>
      }
      //  subTitle="This is a subtitle"
      extra={[<BurgerMenu />]}
    >
      {/*<Descriptions size="small" column={3}>
         <Descriptions.Item label="Created">Lili Qu</Descriptions.Item>
         <Descriptions.Item label="Association">
           <a>421421</a>
         </Descriptions.Item>
         <Descriptions.Item label="Creation Time">2017-01-10</Descriptions.Item>
         <Descriptions.Item label="Effective Time">2017-10-10</Descriptions.Item>
         <Descriptions.Item label="Remarks">
           Gonghu Road, Xihu District, Hangzhou, Zhejiang, China
         </Descriptions.Item>
       </Descriptions>*/}
    </PageHeader>
  </div>
);

const Header = () => (
  <header className='Homepage'>
    <MainHeader />
  </header>
);

export default Header;
