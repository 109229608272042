import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Layout from "./components/Layout";
import AboutUs from "./pages/AboutUs";
import Projects from "./pages/Projects";
import LandfillGass from "./pages/LandfillGass";
import ContactUs from "./pages/ContactUs";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter
    //basename={"custom-plastics"}
    >
      <Routes>
        <Route path='/' element={<Layout />}></Route>
        <Route path='/AboutUs' element={<AboutUs />}></Route>
        <Route path='/Projects' element={<Projects />}></Route>{" "}
        <Route path='/LandfillGass' element={<LandfillGass />}></Route>
        <Route path='/ContactUs' element={<ContactUs />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
